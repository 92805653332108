import * as React from "react";
import Layout from "../presentation/components/layout";
import Top from "../presentation/containers/top";
import Works from "../presentation/containers/works";
import Head from "../presentation/components/head";

// markup
const WorksPage = () => {
  return (
    <>
      <Head
        subtitle="Works"
        description="Antyba合同会社の実績を掲載しています。"
      />
      <Layout>
        <Works />
      </Layout>
    </>
  );
};

export default WorksPage;
