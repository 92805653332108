export interface Work {
  name: string;
  client: string;
  imgSrc: string;
  description: string;
  type: WorkType;
  targets: WorkTarget[];
}

export type WorkType = "product" | "entertainment" | "management-system";
export type WorkTarget =
  | "web"
  | "ios"
  | "android"
  | "windows"
  | "mac"
  | "system"
  | "ar device";

const naru: Work = {
  name: "NARU",
  client: "プロダクト",
  imgSrc: "/works/thumbnail/naru.png",
  description: "強くなるためのオンライン将棋モバイルアプリ",
  type: "product",
  targets: ["ios", "android"],
};

const cycleTimeTrial2: Work = {
  name: "Cycle Time Trial2",
  client: "日本トーター様 / DMM.com 様",
  imgSrc: "/works/thumbnail/cycle-time-trial.png",
  description: "競輪体験型アーケードレースゲーム",
  type: "entertainment",
  targets: ["mac"],
};

const receptionSensing: Work = {
  name: "受付センシングシステム",
  client: "HIS様 / DMM RESORTS様",
  imgSrc: "/works/thumbnail/holographic.webp",
  description:
    "「変なホテル」ホログラフィックチェックインシステムの人感センシングシステム開発",
  type: "entertainment",
  targets: ["system"],
};

const mangaDive: Work = {
  name: "Manga Dive",
  client: "講談社様 / DMM RESORTS様",
  imgSrc: "/works/thumbnail/manga-dive.png",
  description:
    "漫画の一コマに顔をリアルタムに合成。デジタル顔出しパネルアプリ。",
  type: "entertainment",
  targets: ["windows"],
};

const projectionMapping: Work = {
  name: "Projection Mapping",
  client: "HIS様 / DMM RESORTS様",
  imgSrc: "/works/thumbnail/projection-mapping.webp",
  description:
    "「変なホテル」ロビーのプロジェクションマッピング用センシングシステム開発",
  type: "entertainment",
  targets: ["system"],
};

const nreal: Work = {
  name: "nreal 動画視聴アプリ",
  client: "STU様",
  imgSrc: "/works/thumbnail/nreal.jpeg",
  description:
    "AR Glass 「nreal」上で動作する、コメント・エモート付きの動画視聴アプリケーション",
  type: "entertainment",
  targets: ["android"],
};

const arCreator: Work = {
  name: "AR CREATOR",
  client: "STU様",
  imgSrc: "/works/thumbnail/no-image.png",
  description: "AR作品作成プラットフォーム",
  type: "entertainment",
  targets: ["windows"],
};

const liveDx360: Work = {
  name: "LIVE DX 360",
  client: "八咫烏様",
  imgSrc: "/works/thumbnail/live-dx-360.png",
  description: "360°映像生配信システム・アプリケーション",
  type: "entertainment",
  targets: ["ios", "android"],
};

const css: Work = {
  name: "CSS",
  client: "ピクルス様 / トライアド様",
  imgSrc: "/works/thumbnail/css.png",
  description: "整骨院専用予約システム",
  type: "management-system",
  targets: ["web"],
};

const flosta: Work = {
  name: "Flosta",
  client: "八咫烏様",
  imgSrc: "/works/thumbnail/flosta.png",
  description: "贈花専門ECサイト「Flosta」設計・開発",
  type: "management-system",
  targets: ["web"],
};

const tomioka: Work = {
  name: "冨岡製糸場 音声ガイドアプリ",
  client: "THD様",
  imgSrc: "/works/thumbnail/tomioka.webp",
  description:
    "富岡製糸場 国宝「西置繭所」の見どころについて解説する音声ガイドの公式アプリ",
  type: "entertainment",
  targets: ["ios", "android"],
};

const jaxa: Work = {
  name: "SatDyn Viewer",
  client: "JAXA様",
  imgSrc: "/works/thumbnail/tomioka.webp",
  description:
    "富岡製糸場 国宝「西置繭所」の見どころについて解説する音声ガイドの公式アプリ",
  type: "entertainment",
  targets: ["ios", "android"],
};

const works = {
  product: [naru],
  entertainment: [
    arCreator,
    cycleTimeTrial2,
    projectionMapping,
    receptionSensing,
    // tomioka,
    nreal,
    mangaDive,
    liveDx360,
  ],
  managementSystem: [css, flosta],
};

export default works;
