import * as React from "react";
import PageHeader from "../../components/page-header";
import { Card } from "semantic-ui-react";
import works, { Work, WorkType } from "../../../data/works";
import { useMemo } from "react";
import { DummyCard, WorkCard } from "./work-card";

const WorkGroup = (props: { title: string; works: Work[] }) => {
  const dummyCards = useMemo<React.ReactNode>(
    () => [...Array(10)].map((_) => <DummyCard />),
    []
  );
  return (
    <div
      style={{
        margin: "100px 20px 0 20px",
      }}
    >
      <h2>{props.title}</h2>
      <Card.Group
        style={{
          display: "flex",
          animation: "fadein 0.5s forwards",
        }}
      >
        {props.works.map((x) => (
          <WorkCard work={x} />
        ))}
        {dummyCards}
      </Card.Group>
    </div>
  );
};

const Works = () => {
  return (
    <div>
      <PageHeader title="WORKS" imgSrc="/works-header.jpeg" />
      <WorkGroup title="Product" works={works.product} />
      <WorkGroup title="Entertainment" works={works.entertainment} />
      <WorkGroup title="Management System" works={works.managementSystem} />
    </div>
  );
};

export default Works;
