import { Work, WorkTarget } from "../../../data/works";
import { Card, Label, SemanticCOLORS } from "semantic-ui-react";
import styled from "styled-components";
import * as React from "react";

const getLabelColor = (target: WorkTarget): SemanticCOLORS =>
  target == "ios"
    ? "grey"
    : target === "android"
    ? "green"
    : target === "windows"
    ? "blue"
    : target === "mac"
    ? "grey"
    : target === "web"
    ? "orange"
    : "black";

const TargetLabel = (props: { target: WorkTarget }) => (
  <Label size="mini" color={getLabelColor(props.target)}>
    {props.target}
  </Label>
);

export const WorkCard = (props: { work: Work }) => {
  return (
    <Card style={{ flexGrow: 1, maxWidth: "400px" }}>
      <ImageWrapper>
        <ZoomableImage imgSrc={props.work.imgSrc} />
      </ImageWrapper>
      <Card.Content>
        <Card.Meta>
          <span className="date">{props.work.client}</span>
        </Card.Meta>
        <Card.Header>{props.work.name}</Card.Header>
        <Card.Description>{props.work.description}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        {props.work.targets.map((x) => (
          <TargetLabel target={x} />
        ))}
      </Card.Content>
    </Card>
  );
};

export const DummyCard = () => (
  <div
    style={{ flexGrow: 1, boxShadow: "none", height: 0 }}
    className="ui card"
  />
);

const ImageWrapper = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
`;

const ZoomableImage = styled.div<{ imgSrc: string }>`
  width: 100%;
  aspect-ratio: 16/9;
  background-image: url("${(props) => props.imgSrc}");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  transition-property: all;
  transition-duration: 1000ms;
  transition-delay: 0s;
  transition-timing-function: ease;
  &:hover {
    background-size: 120%;
  }
`;
